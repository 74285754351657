<template>
  <PageContainer
    :title="$translations['add-credit-card'].title"
    :action="actions.close"
    :submit="actions.submit"
    :back="actions.back"
  >
    <template v-slot:content>
      <!-- form -->
      <div class="page-segment">
        <div class="page-segment-content">
          <Heading :text="$translations['add-credit-card'].subtitle" />

          <!-- name -->
          <InputTextField
            class="margin-top"
            v-model="form.card_holder"
            :label="$translations['add-credit-card'].name"
          />

          <!-- card number -->
          <InputMaskField
            class="margin-top"
            v-model="form.number"
            mask="#### #### #### ####"
            :label="$translations['add-credit-card'].number"
          />

          <div class="bottom-fields">

            <!-- expiration date -->
            <div class="bottom-fields-field margin-top">
              <div class="text-gray-50 body-small" v-text="$translations['add-credit-card'].exp" />
              <InputMaskField
                v-model="form.exp"
                mask="## / ##"
                label="MM / YY"
                :format-function="formatExpiryDate"
              />
            </div>

            <!-- csv-->
            <div class="bottom-fields-field margin-top">
              <div class="text-gray-50 body-small" v-text="$translations['add-credit-card'].cvc" />
              <InputMaskField
                v-model="form.cvc"
                mask="####"
                label="000"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Wompi footer -->
      <CheckOutWaterMark v-show="currency === 'COP'" />
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Heading, PaymentMethods } from '@seliaco/red-panda'
import { InputTextField, InputMaskField } from '@seliaco/sea-otter'

import CheckOutWaterMark from '@/views/check-out/components/CheckOutWaterMark'
import { PaymentEvent } from '@/types/events'
import ValidateCardDateMixin from "@/mixins/validate-card-date-mixin";

export default {
  name: 'AddCreditCard',
  mixins: [ValidateCardDateMixin],
  props: {
    /**
     * {
     *   name: String,
     *   query: Object,
     *   params: Object
     * }
     */
    next: Object,
    currency: String
  },
  components: {
    CheckOutWaterMark,
    PageContainer,
    Heading,
    InputTextField,
    InputMaskField
  },
  data () {
    return {
      actions: {
        submit: {
          text: this.$translations['add-credit-card'].submit,
          loading: false,
          disabled: true,
          callback: () => this.submit()
        },
        back: {
          callback: () => {
            this.handleNavigateBack()
          }
        }
      },
      form: {
        number: null,
        exp: null,
        cvc: null,
        card_holder: null
      }
    }
  },
  methods: {
    handleNavigateBack (card) {
      if (this.next) {
        const next = this.next

        next.query = {
          ...next.query,
          paymentMethodType: 'CARD',
          paymentMethodId: card
        }

        this.$router.replace({ ...next })
      } else {
        this.$router.go(-1)
      }
    },
    submit () {
      this.actions.submit.loading = true

      PaymentMethods.createCard({
        number: this.form.number,
        exp_month: `${this.form.exp.slice(0, 2)}`,
        exp_year: `${this.form.exp.slice(2, 4)}`,
        cvc: this.form.cvc,
        card_holder: this.form.card_holder
      })
        .then((response) => {
          this.handleNavigateBack(response.card)

          this.$toast({
            text: this.$translations['add-credit-card'].success,
            severity: 'success'
          })
          PaymentEvent.addNewCard({
            paymentMethod: 'CARD',
            user: this.$store.getters['auth/user']
          })
        })
        .catch((error) => {
          this.$toast({
            text: error.message || this.$translations['add-credit-card'].error,
            severity: 'error'
          })
        })
        .finally(() => {
          this.actions.submit.loading = false
        })
    },
    isValid () {
      return Boolean(
        this.form.card_holder &&
          this.form.number &&
          this.form.exp &&
          this.form.cvc
      )
    }
  },
  watch: {
    form: {
      handler () {
        this.actions.submit.disabled = !this.isValid()
      },
      deep: true
    }
  }
}
</script>

<style lang="sass">
.segment .subtitle
  color: var(--gray-60)
.bottom-fields
  display: flex
  gap: 16px
  &-field
    display: flex
    flex-direction: column
    justify-content: flex-start
    gap: 4px
</style>
